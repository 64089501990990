:root {
  /* hexa  */
  --gray: #f7f7f7;
  --darkGray: gray;
  --white: #ffffff;
  --delete: #e74c3c;
  --information: #115293;
  --warn: #e1c848;
  --success: #2bd628;

  --primary: #07ee04;
  --primary2: #078c15;

  /* gba */
  --black_rgb: 0, 0, 0;
  --white_rgb: 255, 255, 255;
  --delete_rgb: 231, 76, 60;
  --warn_rgb: 225, 200, 72;

  --primary_rgb: 7, 238, 4;
  --primary2_rgb: 7, 140, 21;

  --toastify-z-index: 999992;
}

html {
  font-size: 95%;
  animation: fade-in 1.5s ease-in;
}

.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  background-color: var(--gray) !important;
  min-height: 100vh;
  /* padding-top: 4rem; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@keyframes fade-in{
  from {opacity: 0}
  to {opacity: 1}
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
